<template>
  <div class="view-home position-relative parents-list-item">
    <div class="container">
      <nav class="breadcrumbs-block">
        <ul class="breadcrumbs-list">
          <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
          <li class="breadcrumbs-item">Родителям</li>
        </ul>
      </nav>
      <div class="col-12 col-lg-6 mx-lg-auto item-block">
<!--        <el-image-->
<!--            style="width: 100%; height: 100%"-->
<!--            :src="this.parentsListItem.image_path"-->
<!--            fit="fit"-->
<!--            @error="handleImageError"-->
<!--        ></el-image>-->
        <img :src="this.parentsListItem.image_path" width="100%" height="100%" alt="" style="border-radius: 10px"/>
        <div class="description">

        </div>
        <div class="title">
          {{ this.parentsListItem[`title_${$i18n.locale}`] || this.parentsListItem.title_ru}}
        </div>
        <div class="description">
          {{ this.parentsListItem[`description_${$i18n.locale}`] || this.parentsListItem.description_ru}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      parentsListItem: {},
    }
  },
  methods: {

  },
  mounted() {

    const id = this.$route.params.id
    this.$http.get(window.API_ROOT + `/api/parents/${id}`)
        .then(res => {
          this.parentsListItem = res.body
          this.parentsListItem.image_path = window.API_ROOT + '/storage/' + this.parentsListItem.image_path
        })
  }
}

</script>

<style>

.item-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  padding-bottom: 40px;
}

.item-block .el-image {
  border-radius: 10px;
}

.item-block .title {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #343f68;
}

.item-block .description {
  color: #696984;
  font-size: 16px;
}

.parents-list-item .breadcrumbs-block {
  margin: 30px 0;
}

.parents-list-item .breadcrumbs-block li {
  color: #343f68;
}

.parents-list-item .breadcrumbs-block ul li {
  border-right: 1px solid #343f68;
}

.parents-list-item .breadcrumbs-block ul li:last-child {
  border-right: none;
}

.parents-list-item .breadcrumbs-block a {
  color: #343f68;
}


</style>
